import React from "react"
import { Heading } from "../PageTitle/Heading"
import { Paragraph } from "../Paragraph/Paragraph"

interface MemberMappingProps {
  members: {
    nodes: {
      memberFields: {
        image: {
          sourceUrl: string
        }
        name: string
        text: string
      }
    }[]
  }
}

export const MemberMapping: React.FC<MemberMappingProps> = ({ members }) => {
  return (
    <div className="md:flex md:flex-row md:flex-wrap md:items-start lg:mx-36">
      {members.nodes.map(
        (
          {
            memberFields: {
              name,
              text,
              image: { sourceUrl },
            },
          },
          index,
        ) => (
          <div
            key={index}
            className="flex w-full flex-col items-center justify-start p-6 md:w-4/12 lg:w-4/12"
          >
            <div className="flex w-full flex-col items-center overflow-hidden">
              <img
                className="h-72 w-72 overflow-hidden object-cover md:h-60 md:w-60 lg:h-72 lg:w-72"
                style={{
                  borderRadius: "50%",
                }}
                src={sourceUrl}
                alt={""}
              />
            </div>
            <div className="my-8 flex flex-col items-center">
              <Heading className="items-center justify-center whitespace-pre-line text-2xl font-light tracking-widest">
                {name}
              </Heading>
              <Paragraph classname="font-titilliumWeb leading-7 text-left text-base font-light">
                {text}
              </Paragraph>
            </div>
          </div>
        ),
      )}
    </div>
  )
}
