import React from "react"
import { Layout } from "../components/Layout/Layout"
import { graphql } from "gatsby"
import { MemberMapping } from "../components/MemberMapping/MemberMapping"
import { PageTitle } from "../components/PageTitle/PageTitle"

interface TeamProps {
  data: {
    cms: {
      page: {
        title: string
      }
      members: {
        nodes: {
          memberFields: {
            image: {
              sourceUrl: string
            }
            name: string
            text: string
          }
        }[]
      }
    }
  }
}

export const Team: React.FC<TeamProps> = ({ data }) => {
  const dataVariable = data.cms.page.title
  return (
    <Layout withPadding>
      <div className="flex w-full flex-col items-center">
        <div className="flex w-11/12 flex-col items-center">
          <PageTitle>{dataVariable}</PageTitle>
        </div>
        <MemberMapping members={data.cms.members} />
      </div>
    </Layout>
  )
}

export default Team
export const query = graphql`
  query MyQueryTeam {
    cms {
      page(id: "/team-2", idType: URI) {
        title
      }
      members(where: { orderby: { field: DATE, order: ASC } }, first: 20) {
        nodes {
          memberFields {
            image {
              sourceUrl
            }
            name
            text
          }
        }
      }
    }
  }
`
